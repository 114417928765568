#root {
  display       : flex;
  flex-direction: column;
  height        : 100vh;
}

.main {
  flex: 1 0 auto;
}

.header {
  background-color: #000000;
  color           : #ffed00;
  height          : 86px;
  min-height      : 86px;
}

.header .container {
  display    : flex;
  align-items: center;
  height     : 100%;
}

.header .container label {
  height       : 86px;
  font-size    : 30px;
  font-weight  : bold;
  margin-bottom: 0;
  display      : flex;
  align-items  : center;
  padding-left : 25px;
}

.header-menu .container {
  height         : 50px;
  font-size      : 16px;
  font-weight    : bold;
  color          : #000000;
  display        : flex;
  align-items    : center;
  justify-content: space-between;
}

.header-menu .container label {
  margin-bottom: 0;
}

.pointer {
  cursor: pointer;
}

.header-menu {
  background-color: #ffed00;
}

.container-header {
  display    : flex;
  align-items: center;
  width      : 100%;
}

.container-header-div {
  display    : flex;
  align-items: center;
}

.menu-wrapper {
  display        : flex;
  flex-wrap      : wrap;
  margin         : 30px -5px;
  align-items    : center;
  justify-content: space-between;
}

.menu-item-div,
.menu-item-div-active {
  width     : 115px !important;
  height    : 114px !important;
  padding   : 15px 5px;
  margin    : 10px 5px;
  text-align: center;
  color     : #a3a3a3;
  position  : relative;
}

.menu-item-div-active:hover {
  background-color: #ffed00;
  cursor          : pointer;
}

.menu-briefly {
  background-color: #ffffff;
  font-size       : 12px;
  font-weight     : bold;
  position        : absolute;
  top             : 22px;
  right           : -25px;
  transform       : rotate(45deg);
  padding-left    : 20px;
  padding-right   : 20px;
}

.menu-name {
  width      : 100%;
  font-weight: bold;
  font-size  : 12px;
  margin-left: 0px !important;
  padding-top: 10px;
  display    : inline-block;
}

.menu-item-div-active a {
  color          : #000000 !important;
  text-decoration: none !important;
}

.menu-item-div-active a:hover {
  color          : #000000 !important;
  text-decoration: none !important;
}

.montra-filter {
  display         : flex;
  background-color: #000000;
  padding-top     : 10px;
  padding-bottom  : 10px;
  font-size       : 16px;
  color           : #ffffff;
  border          : 1px solid #fff;
}

.montra-filter label {
  margin: 0;
  width : 90px !important;
}

.montra-filter select {
  width           : 90%;
  border          : none;
  background-color: #000000;
  color           : #a3a3a3;
}

.react-daterange-picker__wrapper {
  border: none !important
}

.react-daterange-picker {
  width: 100%;
}

.montra-filter select:focus {
  outline: none;
}

.montra-filter-more-div {
  margin-top: 2px;
}

.montra-filter-more {
  display         : flex;
  background-color: #ebebeb;
  padding-top     : 10px;
  padding-bottom  : 10px;
  font-size       : 16px;
  color           : #000000;
  border          : 1px solid #fff;
}

.montra-filter-more label {
  margin: 0;
  width : 138px;
}

.montra-filter-more select {
  width           : 90%;
  border          : none;
  background-color: #ebebeb;
  color           : #676767;
}

.montra-card {
  margin: 15px;
}

.montra-card-shadow:hover,
.montra-more-cards-button:hover {
  -webkit-box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.40);
  -moz-box-shadow   : 0px 0px 5px 0px rgba(0, 0, 0, 0.40);
  box-shadow        : 0px 0px 5px 0px rgba(0, 0, 0, 0.40);
}

.montra-card-shadow,
.montra-card-shadow label {
  cursor: pointer;
}

.montra-card-child {
  width           : 100%;
  border          : none;
  background-color: #ffffff;
  padding         : 0px;
  cursor          : unset !important;
}

.montra-card-child:focus {
  outline: none;
}

.montra-card-img {
  background-color: #a3a3a3;
  width           : 328px;
  height          : 164px;
  padding         : 0px !important;
}

.montra-card-label {
  padding-top   : 8px;
  padding-bottom: 8px;
}

.montra-card-label label {
  width        : 100%;
  margin-bottom: 2px;
  text-align   : left;
  display      : list-item;
  white-space  : nowrap;
  overflow     : hidden;
  text-overflow: ellipsis;
}

.montra-card-label-title {
  font-weight: bold;
  color      : #000000;
  font-size  : 14px;
}

.montra-card-label-date {
  color    : #a3a3a3;
  font-size: 14px;
}

.montra-card-label-local {
  color    : #a3a3a3;
  font-size: 14px;
}

.montra-info-header {
  padding         : 7.5px;
  margin-Top      : 1em;
  background-Color: #ffffff;
  justify-content : space-between;
  display         : flex;
  border-bottom   : 2px solid #000;
  cursor          : pointer;
}

.montra-info-header-active {
  color           : #fff;
  background-color: #000;
  border-bottom   : 5px solid #ffed00;
}

.montra-info-header label {
  margin     : 0px;
  font-size  : 18px;
  font-weight: bold;
}

.montra-info-content-item {
  display      : flex;
  margin-bottom: 25px;
}

.montra-info-content {
  border-bottom: 2px dotted #bcbbb7;
  padding      : 20px 10px 10px 10px;
}

.montra-info-content-title {
  margin-bottom: 0;
  width        : 100%;
  color        : #000000;
  font-weight  : bold;
  font-size    : 18px;
}

.montra-info-content-description {
  width        : 100%;
  margin-bottom: 0 !important;
  color        : #434343;
  white-space  : pre-wrap;
}

.montra-info-content-media {
  display       : flex;
  padding-bottom: 5px;
  margin-top    : 5px;
  border-bottom : 1px solid #a3a3a3;
}

.montra-info-content-media-title {
  margin-left  : 15px;
  color        : #000000;
  font-size    : 14px;
  font-weight  : bold;
  margin-bottom: 2px;
  width        : 100%;
}

.montra-info-content-media-description {
  margin-left: 15px;
  color      : #a3a3a3;
  font-size  : 14px;
  width      : 100%;
}

.footer-content {
  background-color: #000000;
  height          : 100px;
}

.footer-content .container {
  color          : #ffffff;
  position       : relative;
  height         : 100%;
  padding        : 0px;
  display        : flex;
  justify-content: space-between;
}

.footer-content .container div {
  margin      : auto;
  margin-left : 0px;
  margin-right: 0px;
}

.footer-content .container span {
  font-size: 0.75rem;
}

.footer-line {
  height          : 10px;
  background-color: #ffed00;
}

.footer {
  display: none;
}

#footer-share {
  text-align: left;
  width     : 100%;
  margin-top: 5px;
}

#footer-share button {
  margin-right: 10px;
}

.maps-div {
  width : 100%;
  height: calc(100vh - 246px);
}

.montra-more-cards {
  padding: 0px 15px;
  margin : auto;
}

.montra-more-cards-button {
  text-align      : center;
  background-color: #ffed00;
  padding         : 10px;
  cursor          : pointer;
  font-weight     : bold;
}

@media (min-width: 1200px) {
  .container {
    width: 1024px !important;
  }
}

@media (min-width: 576px) {
  .footer {
    display    : initial;
    flex-shrink: 0;
    height     : 110px;
  }

  .montra-card-div {
    margin-left : -30px !important;
    margin-right: -30px !important;
  }

  .montra-card-label {
    border: 1px solid #c6c6c6;
  }

  .montra-card-wrapper {
    margin-top   : 20px;
    margin-bottom: 20px;
  }

  .menu-container .container {
    padding: 0 !important;
  }
}

@media (max-width: 992px) {
  .footer {
    height: 70px !important;
  }

  .footer-logo {
    display: none;
  }

  .footer-content {
    height: 70px;
  }
}

@media (max-width: 575px) {
  .montra-card-child-wrapper {
    padding-left : 0px !important;
    padding-right: 0px !important;
  }

  .montra-card {
    margin       : 0px;
    margin-top   : 10px;
    border-bottom: 1px solid #a3a3a3;
  }

  .montra-card .row {
    margin-right: 0px !important;
    margin-left : 10px !important
  }

  .montra-card-label {
    border     : none;
    padding-top: 0px;
  }

  .montra-card-img {
    background-color: #a3a3a3;
    height          : 70px;
  }

  .maps-div {
    height: calc(100vh - 136px);
  }

  .loder-aux-div {
    height: calc(100vh - 136px);
  }

  .menu-wrapper {
    margin: 0px !important;
  }

  .menu-item-div,
  .menu-item-div-active {
    height: 94px !important;
    margin: 0px 5px 15px 5px;
  }

  .menu-item-div-active img,
  .menu-item-div img {
    height: 35px;
    width : 35px;
  }

  .menu-name {
    font-size: 8px;
  }

  .lds-roller {
    left: 43% !important;
  }

  .video-alert-div {
    height: 23px;
  }

  .video-alert-div label {
    margin: 0px;
    width : 96%;
  }

  .video-alert-div img {
    display: none;
  }
}

@media (max-width: 1200px) {

  .menu-item-div,
  .menu-item-div-active {
    flex: 1 0 20%;
  }
}

/*LOADER*/

.ajax_loader_back {
  position  : fixed;
  top       : 0px;
  left      : 0px;
  height    : 100%;
  width     : 100%;
  background: rgba(0, 0, 0, 0.4);
  z-index   : 1;
}

.lds-roller {
  width   : 140px !important;
  height  : 64px;
  position: fixed;
  top     : 45%;
  left    : 48%
}

.lds-roller div {
  animation       : lds-roller 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  transform-origin: 32px 32px;
}

.lds-roller div:after {
  content      : " ";
  display      : block;
  position     : absolute;
  width        : 6px;
  height       : 6px;
  border-radius: 50%;
  background   : #f9b700;
  margin       : -3px 0 0 -3px;
}

.lds-roller div:nth-child(1) {
  animation-delay: -0.036s;
}

.lds-roller div:nth-child(1):after {
  top : 50px;
  left: 50px;
}

.lds-roller div:nth-child(2) {
  animation-delay: -0.072s;
}

.lds-roller div:nth-child(2):after {
  top : 54px;
  left: 45px;
}

.lds-roller div:nth-child(3) {
  animation-delay: -0.108s;
}

.lds-roller div:nth-child(3):after {
  top : 57px;
  left: 39px;
}

.lds-roller div:nth-child(4) {
  animation-delay: -0.144s;
}

.lds-roller div:nth-child(4):after {
  top : 58px;
  left: 32px;
}

.lds-roller div:nth-child(5) {
  animation-delay: -0.18s;
}

.lds-roller div:nth-child(5):after {
  top : 57px;
  left: 25px;
}

.lds-roller div:nth-child(6) {
  animation-delay: -0.216s;
}

.lds-roller div:nth-child(6):after {
  top : 54px;
  left: 19px;
}

.lds-roller div:nth-child(7) {
  animation-delay: -0.252s;
}

.lds-roller div:nth-child(7):after {
  top : 50px;
  left: 14px;
}

.lds-roller div:nth-child(8) {
  animation-delay: -0.288s;
}

.lds-roller div:nth-child(8):after {
  top : 45px;
  left: 10px;
}

.lds-roller-text {
  position   : relative;
  top        : 5em;
  right      : 2.5em;
  color      : #f9b700;
  font-weight: bold;
}

@keyframes lds-roller {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.contact-container {
  margin-top: 25px
}

.contact-item {
  border    : 1px solid #ffed00;
  padding   : 25px;
  height    : 230px;
  text-align: center;
}

.contact-item img {
  margin       : auto;
  margin-bottom: 5px;
}

.contact-item label {
  width : 100%;
  margin: 0;
}

.contact-item-title {
  font-weight  : bold;
  color        : #a3a3a3;
  margin-bottom: 5px !important;
}

.main iframe {
  width : 100%;
  height: 100%;
  margin: 0;
}

.vimeo div {
  width : 100%;
  height: 100%;
}

.vp-center {
  display: inline !important;
}

.montra-filter-more select option,
.montra-filter select option {
  background-color: #fff;
  color           : #676767;
  box-shadow      : 0px 3px 6px 0px rgba(0, 0, 0, 0.3);
}

.montra-filter-more select option span:checked,
.montra-filter select option span:checked,
.montra-filter-more select option span:hover,
.montra-filter select option span:hover {
  background-color: #e6e6e6 !important;
  color           : #000000 !important;
  box-shadow      : inset 20px 20px #e6e6e6;
  width           : 100%;
  height          : 100%;
}

.ant-picker-input input {
  text-align: center;
  color     : #676767 !important;
}

::-webkit-input-placeholder {
  /* WebKit, Blink, Edge */
  color: #676767 !important;
}

:-moz-placeholder {
  /* Mozilla Firefox 4 to 18 */
  color  : #676767 !important;
  opacity: 1;
}

::-moz-placeholder {
  /* Mozilla Firefox 19+ */
  color  : #676767 !important;
  opacity: 1;
}

:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: #676767 !important;
}

::-ms-input-placeholder {
  /* Microsoft Edge */
  color: #676767 !important;
}

::placeholder {
  /* Most modern browsers support this now. */
  color: #676767 !important;
}

.ant-picker,
.ant-space-item,
.ant-space {
  width: 100%;
}

.ant-picker input {
  font-size: 16px !important;
}

.ant-picker {
  background-color: #ebebeb !important;
  border          : none !important;
}

.ant-picker-focused {
  box-shadow: none;
}

.ant-picker-range .ant-picker-active-bar {
  background: #676767 !important;
}

/* Customize website's scrollbar like Mac OS
Not supports in Firefox and IE */

/* total width */

::-webkit-scrollbar {
  background-color: transparent;
  width           : 16px
}

/* background of the scrollbar except button or resizer */

::-webkit-scrollbar-track {
  background-color: transparent;
}

::-webkit-scrollbar-track:hover {
  background-color: transparent;
}

/* scrollbar itself */

::-webkit-scrollbar-thumb {
  background-color: #babac0;
  border-radius   : 16px;
  border          : 5px solid #fff
}

::-webkit-scrollbar-thumb:hover {
  background-color: #a0a0a5;
  border          : 4px solid #f4f4f4
}

/* set button(top and bottom of the scrollbar) */

::-webkit-scrollbar-button {
  display: none
}

/*.shadow-white:hover, .shadow-white:focus{
  border-radius:50%;
  -webkit-box-shadow: 0px 0px 15px 0px rgba(250,250,250,0.50);
  -moz-box-shadow: 0px 0px 15px 0px rgba(250,250,250,0.50);
  box-shadow: 0px 0px 15px 0px rgba(250,250,250,0.50);
}*/

#install_button {
  width           : 100%;
  border          : none;
  text-align      : left;
  background-color: #ffed00;
  font-weight     : bold;
  position        : absolute;
  bottom          : 0;
}

.video-alert-div {
  position  : absolute;
  width     : 100%;
  padding   : 3px;
  font-size : 12px;
  text-Align: left;
  bottom    : 0px;
}

.streaming {
  background-color: rgb(236, 0, 0, 0.8);
}

.video-alert-div label {
  margin       : 7px 0px 0px 15px;
  color        : #fff;
  font-weight  : 600;
  white-space  : nowrap;
  overflow     : hidden;
  text-overflow: ellipsis;
  width        : 82%;
}

.video-alert-div img {
  float        : right;
  padding-right: 1px;
}

.language_div {
  padding: 0px;
}

.language {
  position        : absolute;
  background-color: white;
  top             : 3px;
  right           : -40px;
  font-size       : 14px;
  width           : 150px;
  box-shadow      : 0px 0px 15px #999;
}

.language_new {
  padding: 5px 10px;
  color  : black;
  cursor : pointer;
}

.gm-style .gm-style-iw-c {
  border-Radius: 0px;
  padding-right: 1px;
}

.gm-ui-hover-effect {
  width : 35px !important;
  height: 35px !important;
}

.gm-ui-hover-effect img {
  width : 20px !important;
  height: 20px !important;
}

@media (max-width: 475px) {
  #buttonsNav {
    display: inline !important;
  }

  #header-buttons {
    display: none !important;
  }
}