/* Position and sizing of burger button */
.bm-burger-button {
  display: none;
}

/* Position and sizing of clickable cross button */
#NavMenu .bm-cross-button {
    display: none;
  }
  
  /* Color/shape of close button cross */
  #NavMenu .bm-cross {
    float: left;
  }


  #NavMenu{
    left:0;
    top:0px;
    bottom: 0px;
    cursor: auto;
  }

  .bm-overlay {
    top:0px;
    left: 0px;
}

#NavMenu .bm-menu {
  background: #fff;
  padding: 0px;
  font-size: 1.15em;
  overflow-y: hidden;
}

#NavMenu .menu-title{
  background-color: #000000;
  color: #ffed00;
  padding-left: 15px;
}

#NavMenu .menu-title:hover{
  text-decoration: none;   
} 
#NavMenu .menu-item {
    padding: 15px;
}

#NavMenu .menu-item img{
    height: 30px;
    width: 30px;
}

#NavMenu .menu-item span{
    margin-left: 15px;
    color: #000;
}

#NavMenu .menu-item:hover{
    text-decoration: none;    
    background-color: #f4f4f4;
}

#NavMenu .menu-item:focus, .menu-title:focus{
  outline: none;
}

#NavMenu .menu-arrow {
    display:none;
}

#NavMenu .active{    
  background-color: #f4f4f4;
}

#NavMenu .archived{
  background-color: #f7f7f7;
  color:#a6a6a5;
  border-bottom: 1px solid #ddd;
  border-top: 1px solid #ddd;
}