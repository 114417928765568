@import url('https://fonts.googleapis.com/css2?family=Montserrat');
body {
  margin: 0;
  font-family: 'Montserrat', Arial !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  min-height: 100vh;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

@supports (-webkit-touch-callout: none) {
  /* CSS specific to iOS devices */
  select, option {
    -webkit-appearance: none;
  }
}

.cc-link {
  color: #fff;
  opacity: .8;
  display: inline-block;
  padding: .2em;
  text-decoration: underline;
}

.cc-link:hover {
  color: #fff;
  opacity: none;
  text-decoration: underline;
}

#rcc-confirm-button {
  min-width: 140px !important;
  display: block !important;
  padding: 10px 15px !important;
  font-size: .9em !important;
  font-weight: 700 !important;
  border-width: 2px !important;
  border-style: solid !important;
  text-align: center !important;
  white-space: nowrap !important;
  border-radius: 5px !important;
}