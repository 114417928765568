#banner-front-wrapper {
    background-color: #EDEDED;
    height: 583px;
    z-index: 10;
}

#banner-front {
    height: 475px;
    background-position: 50% 50%;
    background-size: cover;
    margin-bottom: 108px;
}

#destaques-home-wrapper {
    position: relative;
    top: 144px;
    background: #ffffff;
    padding: 10px;
    height: 426px;
    overflow: hidden;
    box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.3);
}

.destaque-home {
    height: 406px;
    overflow: hidden;
    position: relative;
    display: none;
    position: absolute;
    left: 10px;
    top: 10px;
    -webkit-transition: all .2s ease-out;
    -moz-transition: all .2s ease-out;
    -o-transition: all .2s ease-out;
    transition: all .2s ease-out;
    width: calc(100% - 360px);
}

.destaque-img {
    width: 100%;
    height: 406px;
    background-repeat: no-repeat;
    background-position: center center;
    object-fit: contain;
}

.destaque-home.active {
    display: inline;
}

#previews {
    position: absolute;
    right: 10px;
    top: 10px;
    width: 344px;
    height: 406px;
    background-color: #FCFCFC;
    padding-left: 10px;
}

.destaque-mini-home {
    padding: 5px 10px;
    cursor: pointer;
    position: relative;
    border-bottom: 1px #a3a3a3 solid;
    padding-left: 10px;
}

.destaque-mini-home img {
    float: left;
    width: 135px;
}

.text-content {
    float: right;
    width: 100%;
    height: 71px;
    overflow: hidden;
}

.destaque-mini-home:hover, .destaque-mini-home.active {
    background: #e6e6e6;
    border-left: 5px #ec0000 solid;
    padding-left: 5px;
}

#banner-front-inner {
    width: 100%;
    margin: 0;
}

.destaque-mini-title {
    color: #000000;
    font-size: 13px;
    font-weight: bold;
    width: 100%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    margin-bottom: -5px;
}
.destaque-mini-desctiprion {
    color: #676767;
    font-size: 13px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    margin-bottom: 0px;
    margin-bottom: -5px;
}

.destaque-mini-date{
    color:#ec0000;
    font-size: 13px;
    font-weight: 600;
    margin-bottom: 0px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    text-transform: uppercase;
    margin-bottom: -5px;
}

.destaque-mini-home-title {
    color: #fff;
    font-size: 20px;
    font-weight: bold;
    margin-bottom: -5px;
    width: 100%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
.destaque-mini-home-desctiprion {
    color: #fff;
    font-size: 14px;
    font-weight: 600;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    margin:0px;
}
.destaque-mini-home-date{
    margin: 0px;
    background-color: #ec0000;
    padding: 0px 10px;
    color: #fff;
    font-size: 14px;
    font-weight: 600;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    text-transform: uppercase;
}

.destaque-mini-category {
    color: #676767;
}

.destaque-home-text-content {
    width: 100%;
    bottom: 0px;
    overflow: hidden;
    position: absolute;
    padding: 10px 15px;
    color: #fff;
}

#getMore-div, #getMore-mini-div{
    background-color: #1d1d1b;
    padding: 5px;
    display: flex;
    justify-content: space-between;
    height: 35px;
    padding-left: 10px;
}

#getMore-mini-div{
    display:none;
}

#banner-front-wrapper #banner-front .container {
    padding: 0px;
}

@media only screen and (min-width :0px) and (max-width :900px) {
    #banner-front-wrapper {
        height: auto;
        min-height: 120px;
    }
    #banner-front {
        height: auto;
        background: transparent !important;
        margin-bottom: 0px;
    }
    #destaques-home-wrapper {
        top: 0;
        background: #ffffff;
        padding: 0px;
        height: 255px;
        box-shadow: none;
    }
    .destaque-home {
        width: 100%;
        height: 220px;
        top: 0px;
        left: 0;
    }

    .destaques-home-wrapper-homepage{
        height: 300px !important;
    }

    .destaque-home-homepage {
        top: 45px !important;
    }

    .previews-homepage{
        bottom: 10px  !important;;
    }

    #previews {
        width: 100%;
        height: 20px;
        top: auto;
        bottom: 5px;
        background-color: transparent;
        text-align: center;
        left: 0;
    }
    .destaque-mini-home {
        line-height: 0;
        position: relative;
        text-indent: -99999px;
        background: #e6e6e6;
        display: inline-block;
        width: 15px;
        height: 15px !important;
        margin: 0 10px;
        padding: 0;
        border: 0 !important;
        border-radius: none;
    }
    .destaque-mini-home:hover, .destaque-mini-home.active {
        background: #000000;
    }
    #banners-front {
        padding-top: 25px;
        margin-bottom: 25px;
    }
    #banner-front #destaques-home-wrapper #previews .destaque-mini-home * {
        display: none !important;
    }
   .destaque-img {
        height: 220px;
    }
    #banner-front-wrapper #banner-front .container {
        max-width: 100%;
    }
    #getMore-div{
        display: none;
    }
    #getMore-mini-div{
        display:flex;
        height: 45px;
        padding-top: 11px;
    }
}