@import url(https://fonts.googleapis.com/css2?family=Montserrat);
body {
  margin: 0;
  font-family: 'Montserrat', Arial !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  min-height: 100vh;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

@supports (-webkit-touch-callout: none) {
  /* CSS specific to iOS devices */
  select, option {
    -webkit-appearance: none;
  }
}

.cc-link {
  color: #fff;
  opacity: .8;
  display: inline-block;
  padding: .2em;
  text-decoration: underline;
}

.cc-link:hover {
  color: #fff;
  opacity: none;
  text-decoration: underline;
}

#rcc-confirm-button {
  min-width: 140px !important;
  display: block !important;
  padding: 10px 15px !important;
  font-size: .9em !important;
  font-weight: 700 !important;
  border-width: 2px !important;
  border-style: solid !important;
  text-align: center !important;
  white-space: nowrap !important;
  border-radius: 5px !important;
}
#root {
  display       : flex;
  flex-direction: column;
  height        : 100vh;
}

.main {
  flex: 1 0 auto;
}

.header {
  background-color: #000000;
  color           : #ffed00;
  height          : 86px;
  min-height      : 86px;
}

.header .container {
  display    : flex;
  align-items: center;
  height     : 100%;
}

.header .container label {
  height       : 86px;
  font-size    : 30px;
  font-weight  : bold;
  margin-bottom: 0;
  display      : flex;
  align-items  : center;
  padding-left : 25px;
}

.header-menu .container {
  height         : 50px;
  font-size      : 16px;
  font-weight    : bold;
  color          : #000000;
  display        : flex;
  align-items    : center;
  justify-content: space-between;
}

.header-menu .container label {
  margin-bottom: 0;
}

.pointer {
  cursor: pointer;
}

.header-menu {
  background-color: #ffed00;
}

.container-header {
  display    : flex;
  align-items: center;
  width      : 100%;
}

.container-header-div {
  display    : flex;
  align-items: center;
}

.menu-wrapper {
  display        : flex;
  flex-wrap      : wrap;
  margin         : 30px -5px;
  align-items    : center;
  justify-content: space-between;
}

.menu-item-div,
.menu-item-div-active {
  width     : 115px !important;
  height    : 114px !important;
  padding   : 15px 5px;
  margin    : 10px 5px;
  text-align: center;
  color     : #a3a3a3;
  position  : relative;
}

.menu-item-div-active:hover {
  background-color: #ffed00;
  cursor          : pointer;
}

.menu-briefly {
  background-color: #ffffff;
  font-size       : 12px;
  font-weight     : bold;
  position        : absolute;
  top             : 22px;
  right           : -25px;
  transform       : rotate(45deg);
  padding-left    : 20px;
  padding-right   : 20px;
}

.menu-name {
  width      : 100%;
  font-weight: bold;
  font-size  : 12px;
  margin-left: 0px !important;
  padding-top: 10px;
  display    : inline-block;
}

.menu-item-div-active a {
  color          : #000000 !important;
  text-decoration: none !important;
}

.menu-item-div-active a:hover {
  color          : #000000 !important;
  text-decoration: none !important;
}

.montra-filter {
  display         : flex;
  background-color: #000000;
  padding-top     : 10px;
  padding-bottom  : 10px;
  font-size       : 16px;
  color           : #ffffff;
  border          : 1px solid #fff;
}

.montra-filter label {
  margin: 0;
  width : 90px !important;
}

.montra-filter select {
  width           : 90%;
  border          : none;
  background-color: #000000;
  color           : #a3a3a3;
}

.react-daterange-picker__wrapper {
  border: none !important
}

.react-daterange-picker {
  width: 100%;
}

.montra-filter select:focus {
  outline: none;
}

.montra-filter-more-div {
  margin-top: 2px;
}

.montra-filter-more {
  display         : flex;
  background-color: #ebebeb;
  padding-top     : 10px;
  padding-bottom  : 10px;
  font-size       : 16px;
  color           : #000000;
  border          : 1px solid #fff;
}

.montra-filter-more label {
  margin: 0;
  width : 138px;
}

.montra-filter-more select {
  width           : 90%;
  border          : none;
  background-color: #ebebeb;
  color           : #676767;
}

.montra-card {
  margin: 15px;
}

.montra-card-shadow:hover,
.montra-more-cards-button:hover {
  box-shadow        : 0px 0px 5px 0px rgba(0, 0, 0, 0.40);
}

.montra-card-shadow,
.montra-card-shadow label {
  cursor: pointer;
}

.montra-card-child {
  width           : 100%;
  border          : none;
  background-color: #ffffff;
  padding         : 0px;
  cursor          : unset !important;
}

.montra-card-child:focus {
  outline: none;
}

.montra-card-img {
  background-color: #a3a3a3;
  width           : 328px;
  height          : 164px;
  padding         : 0px !important;
}

.montra-card-label {
  padding-top   : 8px;
  padding-bottom: 8px;
}

.montra-card-label label {
  width        : 100%;
  margin-bottom: 2px;
  text-align   : left;
  display      : list-item;
  white-space  : nowrap;
  overflow     : hidden;
  text-overflow: ellipsis;
}

.montra-card-label-title {
  font-weight: bold;
  color      : #000000;
  font-size  : 14px;
}

.montra-card-label-date {
  color    : #a3a3a3;
  font-size: 14px;
}

.montra-card-label-local {
  color    : #a3a3a3;
  font-size: 14px;
}

.montra-info-header {
  padding         : 7.5px;
  margin-Top      : 1em;
  background-Color: #ffffff;
  justify-content : space-between;
  display         : flex;
  border-bottom   : 2px solid #000;
  cursor          : pointer;
}

.montra-info-header-active {
  color           : #fff;
  background-color: #000;
  border-bottom   : 5px solid #ffed00;
}

.montra-info-header label {
  margin     : 0px;
  font-size  : 18px;
  font-weight: bold;
}

.montra-info-content-item {
  display      : flex;
  margin-bottom: 25px;
}

.montra-info-content {
  border-bottom: 2px dotted #bcbbb7;
  padding      : 20px 10px 10px 10px;
}

.montra-info-content-title {
  margin-bottom: 0;
  width        : 100%;
  color        : #000000;
  font-weight  : bold;
  font-size    : 18px;
}

.montra-info-content-description {
  width        : 100%;
  margin-bottom: 0 !important;
  color        : #434343;
  white-space  : pre-wrap;
}

.montra-info-content-media {
  display       : flex;
  padding-bottom: 5px;
  margin-top    : 5px;
  border-bottom : 1px solid #a3a3a3;
}

.montra-info-content-media-title {
  margin-left  : 15px;
  color        : #000000;
  font-size    : 14px;
  font-weight  : bold;
  margin-bottom: 2px;
  width        : 100%;
}

.montra-info-content-media-description {
  margin-left: 15px;
  color      : #a3a3a3;
  font-size  : 14px;
  width      : 100%;
}

.footer-content {
  background-color: #000000;
  height          : 100px;
}

.footer-content .container {
  color          : #ffffff;
  position       : relative;
  height         : 100%;
  padding        : 0px;
  display        : flex;
  justify-content: space-between;
}

.footer-content .container div {
  margin      : auto;
  margin-left : 0px;
  margin-right: 0px;
}

.footer-content .container span {
  font-size: 0.75rem;
}

.footer-line {
  height          : 10px;
  background-color: #ffed00;
}

.footer {
  display: none;
}

#footer-share {
  text-align: left;
  width     : 100%;
  margin-top: 5px;
}

#footer-share button {
  margin-right: 10px;
}

.maps-div {
  width : 100%;
  height: calc(100vh - 246px);
}

.montra-more-cards {
  padding: 0px 15px;
  margin : auto;
}

.montra-more-cards-button {
  text-align      : center;
  background-color: #ffed00;
  padding         : 10px;
  cursor          : pointer;
  font-weight     : bold;
}

@media (min-width: 1200px) {
  .container {
    width: 1024px !important;
  }
}

@media (min-width: 576px) {
  .footer {
    display    : initial;
    flex-shrink: 0;
    height     : 110px;
  }

  .montra-card-div {
    margin-left : -30px !important;
    margin-right: -30px !important;
  }

  .montra-card-label {
    border: 1px solid #c6c6c6;
  }

  .montra-card-wrapper {
    margin-top   : 20px;
    margin-bottom: 20px;
  }

  .menu-container .container {
    padding: 0 !important;
  }
}

@media (max-width: 992px) {
  .footer {
    height: 70px !important;
  }

  .footer-logo {
    display: none;
  }

  .footer-content {
    height: 70px;
  }
}

@media (max-width: 575px) {
  .montra-card-child-wrapper {
    padding-left : 0px !important;
    padding-right: 0px !important;
  }

  .montra-card {
    margin       : 0px;
    margin-top   : 10px;
    border-bottom: 1px solid #a3a3a3;
  }

  .montra-card .row {
    margin-right: 0px !important;
    margin-left : 10px !important
  }

  .montra-card-label {
    border     : none;
    padding-top: 0px;
  }

  .montra-card-img {
    background-color: #a3a3a3;
    height          : 70px;
  }

  .maps-div {
    height: calc(100vh - 136px);
  }

  .loder-aux-div {
    height: calc(100vh - 136px);
  }

  .menu-wrapper {
    margin: 0px !important;
  }

  .menu-item-div,
  .menu-item-div-active {
    height: 94px !important;
    margin: 0px 5px 15px 5px;
  }

  .menu-item-div-active img,
  .menu-item-div img {
    height: 35px;
    width : 35px;
  }

  .menu-name {
    font-size: 8px;
  }

  .lds-roller {
    left: 43% !important;
  }

  .video-alert-div {
    height: 23px;
  }

  .video-alert-div label {
    margin: 0px;
    width : 96%;
  }

  .video-alert-div img {
    display: none;
  }
}

@media (max-width: 1200px) {

  .menu-item-div,
  .menu-item-div-active {
    flex: 1 0 20%;
  }
}

/*LOADER*/

.ajax_loader_back {
  position  : fixed;
  top       : 0px;
  left      : 0px;
  height    : 100%;
  width     : 100%;
  background: rgba(0, 0, 0, 0.4);
  z-index   : 1;
}

.lds-roller {
  width   : 140px !important;
  height  : 64px;
  position: fixed;
  top     : 45%;
  left    : 48%
}

.lds-roller div {
  animation       : lds-roller 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  transform-origin: 32px 32px;
}

.lds-roller div:after {
  content      : " ";
  display      : block;
  position     : absolute;
  width        : 6px;
  height       : 6px;
  border-radius: 50%;
  background   : #f9b700;
  margin       : -3px 0 0 -3px;
}

.lds-roller div:nth-child(1) {
  animation-delay: -0.036s;
}

.lds-roller div:nth-child(1):after {
  top : 50px;
  left: 50px;
}

.lds-roller div:nth-child(2) {
  animation-delay: -0.072s;
}

.lds-roller div:nth-child(2):after {
  top : 54px;
  left: 45px;
}

.lds-roller div:nth-child(3) {
  animation-delay: -0.108s;
}

.lds-roller div:nth-child(3):after {
  top : 57px;
  left: 39px;
}

.lds-roller div:nth-child(4) {
  animation-delay: -0.144s;
}

.lds-roller div:nth-child(4):after {
  top : 58px;
  left: 32px;
}

.lds-roller div:nth-child(5) {
  animation-delay: -0.18s;
}

.lds-roller div:nth-child(5):after {
  top : 57px;
  left: 25px;
}

.lds-roller div:nth-child(6) {
  animation-delay: -0.216s;
}

.lds-roller div:nth-child(6):after {
  top : 54px;
  left: 19px;
}

.lds-roller div:nth-child(7) {
  animation-delay: -0.252s;
}

.lds-roller div:nth-child(7):after {
  top : 50px;
  left: 14px;
}

.lds-roller div:nth-child(8) {
  animation-delay: -0.288s;
}

.lds-roller div:nth-child(8):after {
  top : 45px;
  left: 10px;
}

.lds-roller-text {
  position   : relative;
  top        : 5em;
  right      : 2.5em;
  color      : #f9b700;
  font-weight: bold;
}

@keyframes lds-roller {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.contact-container {
  margin-top: 25px
}

.contact-item {
  border    : 1px solid #ffed00;
  padding   : 25px;
  height    : 230px;
  text-align: center;
}

.contact-item img {
  margin       : auto;
  margin-bottom: 5px;
}

.contact-item label {
  width : 100%;
  margin: 0;
}

.contact-item-title {
  font-weight  : bold;
  color        : #a3a3a3;
  margin-bottom: 5px !important;
}

.main iframe {
  width : 100%;
  height: 100%;
  margin: 0;
}

.vimeo div {
  width : 100%;
  height: 100%;
}

.vp-center {
  display: inline !important;
}

.montra-filter-more select option,
.montra-filter select option {
  background-color: #fff;
  color           : #676767;
  box-shadow      : 0px 3px 6px 0px rgba(0, 0, 0, 0.3);
}

.montra-filter-more select option span:checked,
.montra-filter select option span:checked,
.montra-filter-more select option span:hover,
.montra-filter select option span:hover {
  background-color: #e6e6e6 !important;
  color           : #000000 !important;
  box-shadow      : inset 20px 20px #e6e6e6;
  width           : 100%;
  height          : 100%;
}

.ant-picker-input input {
  text-align: center;
  color     : #676767 !important;
}

::placeholder {
  /* Most modern browsers support this now. */
  color: #676767 !important;
}

.ant-picker,
.ant-space-item,
.ant-space {
  width: 100%;
}

.ant-picker input {
  font-size: 16px !important;
}

.ant-picker {
  background-color: #ebebeb !important;
  border          : none !important;
}

.ant-picker-focused {
  box-shadow: none;
}

.ant-picker-range .ant-picker-active-bar {
  background: #676767 !important;
}

/* Customize website's scrollbar like Mac OS
Not supports in Firefox and IE */

/* total width */

::-webkit-scrollbar {
  background-color: transparent;
  width           : 16px
}

/* background of the scrollbar except button or resizer */

::-webkit-scrollbar-track {
  background-color: transparent;
}

::-webkit-scrollbar-track:hover {
  background-color: transparent;
}

/* scrollbar itself */

::-webkit-scrollbar-thumb {
  background-color: #babac0;
  border-radius   : 16px;
  border          : 5px solid #fff
}

::-webkit-scrollbar-thumb:hover {
  background-color: #a0a0a5;
  border          : 4px solid #f4f4f4
}

/* set button(top and bottom of the scrollbar) */

::-webkit-scrollbar-button {
  display: none
}

/*.shadow-white:hover, .shadow-white:focus{
  border-radius:50%;
  -webkit-box-shadow: 0px 0px 15px 0px rgba(250,250,250,0.50);
  -moz-box-shadow: 0px 0px 15px 0px rgba(250,250,250,0.50);
  box-shadow: 0px 0px 15px 0px rgba(250,250,250,0.50);
}*/

#install_button {
  width           : 100%;
  border          : none;
  text-align      : left;
  background-color: #ffed00;
  font-weight     : bold;
  position        : absolute;
  bottom          : 0;
}

.video-alert-div {
  position  : absolute;
  width     : 100%;
  padding   : 3px;
  font-size : 12px;
  text-Align: left;
  bottom    : 0px;
}

.streaming {
  background-color: rgb(236, 0, 0, 0.8);
}

.video-alert-div label {
  margin       : 7px 0px 0px 15px;
  color        : #fff;
  font-weight  : 600;
  white-space  : nowrap;
  overflow     : hidden;
  text-overflow: ellipsis;
  width        : 82%;
}

.video-alert-div img {
  float        : right;
  padding-right: 1px;
}

.language_div {
  padding: 0px;
}

.language {
  position        : absolute;
  background-color: white;
  top             : 3px;
  right           : -40px;
  font-size       : 14px;
  width           : 150px;
  box-shadow      : 0px 0px 15px #999;
}

.language_new {
  padding: 5px 10px;
  color  : black;
  cursor : pointer;
}

.gm-style .gm-style-iw-c {
  border-Radius: 0px;
  padding-right: 1px;
}

.gm-ui-hover-effect {
  width : 35px !important;
  height: 35px !important;
}

.gm-ui-hover-effect img {
  width : 20px !important;
  height: 20px !important;
}

@media (max-width: 475px) {
  #buttonsNav {
    display: inline !important;
  }

  #header-buttons {
    display: none !important;
  }
}
/* Position and sizing of burger button */
.bm-burger-button {
  display: none;
}

/* Position and sizing of clickable cross button */
#NavMenu .bm-cross-button {
    display: none;
  }
  
  /* Color/shape of close button cross */
  #NavMenu .bm-cross {
    float: left;
  }


  #NavMenu{
    left:0;
    top:0px;
    bottom: 0px;
    cursor: auto;
  }

  .bm-overlay {
    top:0px;
    left: 0px;
}

#NavMenu .bm-menu {
  background: #fff;
  padding: 0px;
  font-size: 1.15em;
  overflow-y: hidden;
}

#NavMenu .menu-title{
  background-color: #000000;
  color: #ffed00;
  padding-left: 15px;
}

#NavMenu .menu-title:hover{
  text-decoration: none;   
} 
#NavMenu .menu-item {
    padding: 15px;
}

#NavMenu .menu-item img{
    height: 30px;
    width: 30px;
}

#NavMenu .menu-item span{
    margin-left: 15px;
    color: #000;
}

#NavMenu .menu-item:hover{
    text-decoration: none;    
    background-color: #f4f4f4;
}

#NavMenu .menu-item:focus, .menu-title:focus{
  outline: none;
}

#NavMenu .menu-arrow {
    display:none;
}

#NavMenu .active{    
  background-color: #f4f4f4;
}

#NavMenu .archived{
  background-color: #f7f7f7;
  color:#a6a6a5;
  border-bottom: 1px solid #ddd;
  border-top: 1px solid #ddd;
}

  /* Position and sizing of clickable cross button */
  #SettingsMenu .bm-cross-button {
      display: none;
    }
    
    /* Color/shape of close button cross */
    #SettingsMenu .bm-cross {
      float: left;
    }
  
  
    #SettingsMenu{
      right:0;
      top:0px;
      bottom: 0px;
      cursor: auto;
    }
   
  #SettingsMenu .bm-menu {
    background: #000000;
    padding: 0px;
    font-size: 1.15em;
    overflow-y: hidden;
  }
  
  #SettingsMenu .menu-title{
    background-color: #000000;
    color: #ffed00;
    padding-left: 15px;
  }
  
  #SettingsMenu .menu-title:hover{
    text-decoration: none;   
  } 
  #SettingsMenu .menu-item {
      padding: 15px;
  }
  
  #SettingsMenu .menu-item img{
      height: 30px;
      width: 30px;
  }
  
  #SettingsMenu .menu-item span{
      margin-left: 15px;
      color: #f4f4f4;
  }
  
  #SettingsMenu .menu-item:hover{
      text-decoration: none;    
      background-color: #000;
  }
  
  #SettingsMenu .menu-item:focus, .menu-title:focus{
    outline: none;
  }
  
  #SettingsMenu .menu-arrow {
      display:none;
  }
  
#banner-front-wrapper {
    background-color: #EDEDED;
    height: 583px;
    z-index: 10;
}

#banner-front {
    height: 475px;
    background-position: 50% 50%;
    background-size: cover;
    margin-bottom: 108px;
}

#destaques-home-wrapper {
    position: relative;
    top: 144px;
    background: #ffffff;
    padding: 10px;
    height: 426px;
    overflow: hidden;
    box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.3);
}

.destaque-home {
    height: 406px;
    overflow: hidden;
    position: relative;
    display: none;
    position: absolute;
    left: 10px;
    top: 10px;
    transition: all .2s ease-out;
    width: calc(100% - 360px);
}

.destaque-img {
    width: 100%;
    height: 406px;
    background-repeat: no-repeat;
    background-position: center center;
    object-fit: contain;
}

.destaque-home.active {
    display: inline;
}

#previews {
    position: absolute;
    right: 10px;
    top: 10px;
    width: 344px;
    height: 406px;
    background-color: #FCFCFC;
    padding-left: 10px;
}

.destaque-mini-home {
    padding: 5px 10px;
    cursor: pointer;
    position: relative;
    border-bottom: 1px #a3a3a3 solid;
    padding-left: 10px;
}

.destaque-mini-home img {
    float: left;
    width: 135px;
}

.text-content {
    float: right;
    width: 100%;
    height: 71px;
    overflow: hidden;
}

.destaque-mini-home:hover, .destaque-mini-home.active {
    background: #e6e6e6;
    border-left: 5px #ec0000 solid;
    padding-left: 5px;
}

#banner-front-inner {
    width: 100%;
    margin: 0;
}

.destaque-mini-title {
    color: #000000;
    font-size: 13px;
    font-weight: bold;
    width: 100%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    margin-bottom: -5px;
}
.destaque-mini-desctiprion {
    color: #676767;
    font-size: 13px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    margin-bottom: 0px;
    margin-bottom: -5px;
}

.destaque-mini-date{
    color:#ec0000;
    font-size: 13px;
    font-weight: 600;
    margin-bottom: 0px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    text-transform: uppercase;
    margin-bottom: -5px;
}

.destaque-mini-home-title {
    color: #fff;
    font-size: 20px;
    font-weight: bold;
    margin-bottom: -5px;
    width: 100%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
.destaque-mini-home-desctiprion {
    color: #fff;
    font-size: 14px;
    font-weight: 600;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    margin:0px;
}
.destaque-mini-home-date{
    margin: 0px;
    background-color: #ec0000;
    padding: 0px 10px;
    color: #fff;
    font-size: 14px;
    font-weight: 600;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    text-transform: uppercase;
}

.destaque-mini-category {
    color: #676767;
}

.destaque-home-text-content {
    width: 100%;
    bottom: 0px;
    overflow: hidden;
    position: absolute;
    padding: 10px 15px;
    color: #fff;
}

#getMore-div, #getMore-mini-div{
    background-color: #1d1d1b;
    padding: 5px;
    display: flex;
    justify-content: space-between;
    height: 35px;
    padding-left: 10px;
}

#getMore-mini-div{
    display:none;
}

#banner-front-wrapper #banner-front .container {
    padding: 0px;
}

@media only screen and (min-width :0px) and (max-width :900px) {
    #banner-front-wrapper {
        height: auto;
        min-height: 120px;
    }
    #banner-front {
        height: auto;
        background: transparent !important;
        margin-bottom: 0px;
    }
    #destaques-home-wrapper {
        top: 0;
        background: #ffffff;
        padding: 0px;
        height: 255px;
        box-shadow: none;
    }
    .destaque-home {
        width: 100%;
        height: 220px;
        top: 0px;
        left: 0;
    }

    .destaques-home-wrapper-homepage{
        height: 300px !important;
    }

    .destaque-home-homepage {
        top: 45px !important;
    }

    .previews-homepage{
        bottom: 10px  !important;;
    }

    #previews {
        width: 100%;
        height: 20px;
        top: auto;
        bottom: 5px;
        background-color: transparent;
        text-align: center;
        left: 0;
    }
    .destaque-mini-home {
        line-height: 0;
        position: relative;
        text-indent: -99999px;
        background: #e6e6e6;
        display: inline-block;
        width: 15px;
        height: 15px !important;
        margin: 0 10px;
        padding: 0;
        border: 0 !important;
        border-radius: none;
    }
    .destaque-mini-home:hover, .destaque-mini-home.active {
        background: #000000;
    }
    #banners-front {
        padding-top: 25px;
        margin-bottom: 25px;
    }
    #banner-front #destaques-home-wrapper #previews .destaque-mini-home * {
        display: none !important;
    }
   .destaque-img {
        height: 220px;
    }
    #banner-front-wrapper #banner-front .container {
        max-width: 100%;
    }
    #getMore-div{
        display: none;
    }
    #getMore-mini-div{
        display:flex;
        height: 45px;
        padding-top: 11px;
    }
}
.one-destaque-home{
    width: calc(100% - 20px) !important;
}

@media only screen and (min-width :0px) and (max-width :900px) {
    .one-destaque-home{
        width: 100% !important;
    }
}
.montra-destaque-home {
    display: none;
    width: 100%;
    top: 0;
    left: 0;
}

.montra-destaque-home.active {
    display: initial;
}

.montra-previews {
    width: 100%;
    height: 475px;
    text-align: center;
    position: absolute;
    top: 136px;
    z-index: 10;
    background: rgb(0, 0, 0);
    background: linear-gradient(0deg, rgba(0, 0, 0, 1) 8%, rgba(255, 0, 26, 0) 50%);
}

#montra-previews-minis {
    margin-top: 445px;
    margin-left: -10px;
}

.montra-previews .container {
    justify-content: space-between;
}

#montra-destaque-banner-inner {
    width: 100%;
    margin: 0;
}

.montra-destaque-title {
    color: #000000;
    font-size: 20px;
    font-weight: bold;
    margin-bottom: 2px;
}

.montra-destaque-content {
    width: 100%;
    min-height: 71px;
    display: inherit;
    padding: 10px 0px;
    background: #e6e6e6;
}

.montra-destaque-buttons {
    min-width: 120px;
    margin-top: 433px;
    display: none;
}

.montra-destaque-buttons-ontitle {
    display: flex;
}

.montra-destaque-buttons img, .montra-destaque-buttons-ontitle img {
    width: 30px;
    height: 30px;
    cursor: pointer;
}

.montra-destaque-mini {
    cursor: pointer;
    text-indent: -99999px;
    background: #e6e6e6;
    display: inline-block;
    width: 15px;
    height: 15px;
    margin: 0 10px;
}

.montra-destaque-mini:hover, .montra-destaque-mini.active {
    background: #ffed00;
}

.montra-destaque-home img {
    width: 100%;
    height: 475px;
}

.montra-destaque-img {
    height: 475px;
    background-color: '#b2b2b2';
}

@media (max-width: 575px) {
    .montra-previews, .montra-destaque-img, .montra-destaque-home img {
        height: 220px;
    }
    #montra-previews-minis {
        margin-top: 190px;
    }
    .montra-destaque-buttons{
        margin-top: 178px;
    }
    .montra-previews .container {
        display: flex;
    }
    .montra-destaque-buttons-ontitle {
        display: none;
    }
    .montra-destaque-buttons {
        display: flex;
    }
    .dropdown-menu {
        left: 25px !important;
        top: 10px !important;
    }
    .montra-destaque-img-container {
        padding: 0px !important;
    }
}

.react-add-to-calendar {
    position: relative;
    display: inline-block;
    margin: 0 auto;
}

.react-add-to-calendar__wrapper {
    cursor: pointer;
}

.react-add-to-calendar__button {
    color: #000;
}

.react-add-to-calendar__icon--right {
    padding-left: 5px;
}

.react-add-to-calendar__icon--left {
    padding-right: 5px;
}

.react-add-to-calendar__dropdown {
    position: absolute;
    top: 30px;
    left: 1px;
    width: 93%;
    padding: 5px 0 5px 8px;
    box-shadow: 1px 3px 6px rgba(0, 0, 0, .15);
    border: 1px solid #a8a8a8;
    background-color: #fff;
    text-align: left;
}

.react-add-to-calendar__dropdown ul {
    list-style: none;
    margin: 0;
}

.react-add-to-calendar__dropdown ul li a {
    color: #000;
    text-decoration: none;
}

.react-add-to-calendar__dropdown ul li a i {
    padding-right: 10px;
}

.dropdown-toggle:after {
    display: none;
}

.btn-primary, .btn-primary:hover, .btn-primary:focus, .dropdown-toggle:hover, .dropdown-toggle:focus, .btn-primary:not(:disabled):not(.disabled).active, .btn-primary:not(:disabled):not(.disabled):active, .show>.btn-primary.dropdown-toggle {
    background-color: transparent;
    border: none;
    padding: 0px;
    outline: none !important;
}

.dropdown-menu {
    min-width: 182px !important;
    background-color: black !important;
    top: 10px !important;
    padding: 0px;
}

@media (min-width: 576px) {
    .dropdown-menu {
        left: -55px !important;
    }
}
