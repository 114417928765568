
  /* Position and sizing of clickable cross button */
  #SettingsMenu .bm-cross-button {
      display: none;
    }
    
    /* Color/shape of close button cross */
    #SettingsMenu .bm-cross {
      float: left;
    }
  
  
    #SettingsMenu{
      right:0;
      top:0px;
      bottom: 0px;
      cursor: auto;
    }
   
  #SettingsMenu .bm-menu {
    background: #000000;
    padding: 0px;
    font-size: 1.15em;
    overflow-y: hidden;
  }
  
  #SettingsMenu .menu-title{
    background-color: #000000;
    color: #ffed00;
    padding-left: 15px;
  }
  
  #SettingsMenu .menu-title:hover{
    text-decoration: none;   
  } 
  #SettingsMenu .menu-item {
      padding: 15px;
  }
  
  #SettingsMenu .menu-item img{
      height: 30px;
      width: 30px;
  }
  
  #SettingsMenu .menu-item span{
      margin-left: 15px;
      color: #f4f4f4;
  }
  
  #SettingsMenu .menu-item:hover{
      text-decoration: none;    
      background-color: #000;
  }
  
  #SettingsMenu .menu-item:focus, .menu-title:focus{
    outline: none;
  }
  
  #SettingsMenu .menu-arrow {
      display:none;
  }
  