.montra-destaque-home {
    display: none;
    width: 100%;
    top: 0;
    left: 0;
}

.montra-destaque-home.active {
    display: initial;
}

.montra-previews {
    width: 100%;
    height: 475px;
    text-align: center;
    position: absolute;
    top: 136px;
    z-index: 10;
    background: rgb(0, 0, 0);
    background: linear-gradient(0deg, rgba(0, 0, 0, 1) 8%, rgba(255, 0, 26, 0) 50%);
}

#montra-previews-minis {
    margin-top: 445px;
    margin-left: -10px;
}

.montra-previews .container {
    justify-content: space-between;
}

#montra-destaque-banner-inner {
    width: 100%;
    margin: 0;
}

.montra-destaque-title {
    color: #000000;
    font-size: 20px;
    font-weight: bold;
    margin-bottom: 2px;
}

.montra-destaque-content {
    width: 100%;
    min-height: 71px;
    display: inherit;
    padding: 10px 0px;
    background: #e6e6e6;
}

.montra-destaque-buttons {
    min-width: 120px;
    margin-top: 433px;
    display: none;
}

.montra-destaque-buttons-ontitle {
    display: flex;
}

.montra-destaque-buttons img, .montra-destaque-buttons-ontitle img {
    width: 30px;
    height: 30px;
    cursor: pointer;
}

.montra-destaque-mini {
    cursor: pointer;
    text-indent: -99999px;
    background: #e6e6e6;
    display: inline-block;
    width: 15px;
    height: 15px;
    margin: 0 10px;
}

.montra-destaque-mini:hover, .montra-destaque-mini.active {
    background: #ffed00;
}

.montra-destaque-home img {
    width: 100%;
    height: 475px;
}

.montra-destaque-img {
    height: 475px;
    background-color: '#b2b2b2';
}

@media (max-width: 575px) {
    .montra-previews, .montra-destaque-img, .montra-destaque-home img {
        height: 220px;
    }
    #montra-previews-minis {
        margin-top: 190px;
    }
    .montra-destaque-buttons{
        margin-top: 178px;
    }
    .montra-previews .container {
        display: flex;
    }
    .montra-destaque-buttons-ontitle {
        display: none;
    }
    .montra-destaque-buttons {
        display: flex;
    }
    .dropdown-menu {
        left: 25px !important;
        top: 10px !important;
    }
    .montra-destaque-img-container {
        padding: 0px !important;
    }
}

.react-add-to-calendar {
    position: relative;
    display: inline-block;
    margin: 0 auto;
}

.react-add-to-calendar__wrapper {
    cursor: pointer;
}

.react-add-to-calendar__button {
    color: #000;
}

.react-add-to-calendar__icon--right {
    padding-left: 5px;
}

.react-add-to-calendar__icon--left {
    padding-right: 5px;
}

.react-add-to-calendar__dropdown {
    position: absolute;
    top: 30px;
    left: 1px;
    width: 93%;
    padding: 5px 0 5px 8px;
    box-shadow: 1px 3px 6px rgba(0, 0, 0, .15);
    border: 1px solid #a8a8a8;
    background-color: #fff;
    text-align: left;
}

.react-add-to-calendar__dropdown ul {
    list-style: none;
    margin: 0;
}

.react-add-to-calendar__dropdown ul li a {
    color: #000;
    text-decoration: none;
}

.react-add-to-calendar__dropdown ul li a i {
    padding-right: 10px;
}

.dropdown-toggle:after {
    display: none;
}

.btn-primary, .btn-primary:hover, .btn-primary:focus, .dropdown-toggle:hover, .dropdown-toggle:focus, .btn-primary:not(:disabled):not(.disabled).active, .btn-primary:not(:disabled):not(.disabled):active, .show>.btn-primary.dropdown-toggle {
    background-color: transparent;
    border: none;
    padding: 0px;
    outline: none !important;
}

.dropdown-menu {
    min-width: 182px !important;
    background-color: black !important;
    top: 10px !important;
    padding: 0px;
}

@media (min-width: 576px) {
    .dropdown-menu {
        left: -55px !important;
    }
}